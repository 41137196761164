import { axios } from '../connection/ConnectionHandler';
import { SHOW_NOTIFICATION } from '../constants';
import { createCrudActions } from '../utils/crudGenerator';

const formatPhotos = (data) => {
    if (!data) {
        return [];
    }
    const formatItem = (item) => ({
        ...item,
        label: `${item.name}`,
        value: item._id,
    });

    if (Array.isArray(data)) {
        return data.map(formatItem);
    }
    return formatItem(data);
};

export const createCustomPhoto = (photo) => async (dispatch) => {
    try {
        dispatch({ type: `PHOTO_FORM_TOGGLE_LOADING` });
        const response = await axios.post('photo', photo);
        const formattedData = formatPhotos(response.data);
        dispatch({ type: `ADD_NEW_PHOTO`, payload: formattedData });
        dispatch({ type: `PHOTO_FORM_TOGGLE_LOADING` });
        dispatch({
            type: SHOW_NOTIFICATION,
            payload: {
                show: true,
                status: "success",
                message: "Foto creado con éxito.",
            },
        });
        return response;
    } catch (err) {
        dispatch({ type: `PHOTO_FORM_TOGGLE_LOADING` });
        return err;
    }
}

export const {
    fetch: fetchPhotos,
    create: createPhoto,
    update: updatePhoto,
    delete: deletePhotos
} = createCrudActions('PHOTO', "Foto", formatPhotos);