import { createCrudActions } from '../utils/crudGenerator';

const formatChallenges = (data) => {
    if (!data) {
        return [];
    }
    const formatItem = (item) => ({
        ...item,
        label: `${item.name}`,
        value: item._id,
    });

    if (Array.isArray(data)) {
        return data.map(formatItem);
    }
    return formatItem(data);
};

export const {
    fetch: fetchChallenges,
    create: createChallenge,
    update: updateChallenge,
    delete: deleteChallenges
} = createCrudActions('CHALLENGE', "Desafío", formatChallenges);