/* BASIC APP CONSTANTS */
export const AUTH = "AUTH";
export const LOGOUT = "LOGOUT";
export const APP_COOKIE_NAME = "APP_COOKIE_NAME";
export const USER_COOKIE_NAME = "USER_COOKIE_NAME";
export const PASS_COOKIE_NAME = "PASS_COOKIE_NAME";
export const USER_ID_COOKIE_NAME = "USER_ID_COOKIE_NAME";
export const SET_CURRENT_ROUTE = "SET_CURRENT_ROUTE";
export const SET_SIDE_MENU_STATE = "SET_SIDE_MENU_STATE";
export const SHOW_NOTIFICATION = "SHOW_NOTIFICATION";
export const CLOSE_NOTIFICATION = "CLOSE_NOTIFICATION";
export const SHOW_ACTION_MENU = "SHOW_ACTION_MENU";
export const HIDE_ACTION_MENU = "HIDE_ACTION_MENU";
export const SHOW_BOTTOM_TOOLBAR = "SHOW_BOTTOM_TOOLBAR";
export const HIDE_BOTTOM_TOOLBAR = "HIDE_BOTTOM_TOOLBAR";
export const SET_USER_LIST = "SET_USER_LIST";
export const EDIT_DISABLED_BUTTONS = "EDIT_DISABLED_BUTTONS";
export const SET_LOGS_LIST = "SET_LOGS_LIST";
export const THEME = "THEME";

/* <---- BASIC APP CONSTANTS ----> */

/* SOCKET CONSTANTS */
export const CONNECTED = "connected";
export const DISCONNECTED = "disconnected";
/* <---- SOCKET CONSTANTS ----> */

/* CONFIGURATION */
export const CONFIGURATION_FORM_TOOGLE_LOADING =
  "CONFIGURATION_FORM_TOOGLE_LOADING";
export const SET_CONFIGURATION = "SET_CONFIGURATION";
/* <---- CONFIGURATION ----> */

/* USER */
export const SET_PROFILE = "SET_PROFILE";
/* <---- USER ----> */

/* USERS */
export const ADD_NEW_USER = "ADD_NEW_USER";
export const UPDATE_USER = "UPDATE_USER";
export const DELETE_USER = "DELETE_USER";
export const OPEN_USER_FORM = "OPEN_USER_FORM";
export const CLOSE_USER_FORM = "CLOSE_USER_FORM";
export const EDIT_SELECTED_USER = "EDIT_SELECTED_USER";
export const USERS_FORM_TOOGLE_LOADING = "USERS_FORM_TOOGLE_LOADING";
/* <---- USERS ----> */

/* PHOTO */
export const SET_PHOTO_LIST = "SET_PHOTO_LIST";
export const ADD_NEW_PHOTO = "ADD_NEW_PHOTO";
export const UPDATE_PHOTO = "UPDATE_PHOTO";
export const DELETE_PHOTO = "DELETE_PHOTO";
export const OPEN_PHOTO_FORM = "OPEN_PHOTO_FORM";
export const CLOSE_PHOTO_FORM = "CLOSE_PHOTO_FORM";
export const EDIT_SELECTED_PHOTO = "EDIT_SELECTED_PHOTO";
export const PHOTO_FORM_TOOGLE_LOADING = "PHOTO_FORM_TOOGLE_LOADING";
/* <---- PHOTO ----> */

/* CHALLENGE */
export const SET_CHALLENGE_LIST = "SET_CHALLENGE_LIST";
export const ADD_NEW_CHALLENGE = "ADD_NEW_CHALLENGE";
export const UPDATE_CHALLENGE = "UPDATE_CHALLENGE";
export const DELETE_CHALLENGE = "DELETE_CHALLENGE";
export const OPEN_CHALLENGE_FORM = "OPEN_CHALLENGE_FORM";
export const CLOSE_CHALLENGE_FORM = "CLOSE_CHALLENGE_FORM";
export const EDIT_SELECTED_CHALLENGE = "EDIT_SELECTED_CHALLENGE";
export const CHALLENGE_FORM_TOOGLE_LOADING = "CHALLENGE_FORM_TOOGLE_LOADING";
/* <---- CHALLENGE ----> */

/* ROUTES */
export const SET_ALL_ROUTES = "SET_ALL_ROUTES";
export const UPDATE_ROUTE = "UPDATE_ROUTE";
/* <---- ROUTES ----> */

/* ALERT */
export const SET_ALERT_LIST = "SET_ALERT_LIST";
export const ADD_NEW_ALERT = "ADD_NEW_ALERT";
export const UPDATE_ALERT = "UPDATE_ALERT";
export const DELETE_ALERT = "DELETE_ALERT";
export const OPEN_ALERT_FORM = "OPEN_ALERT_FORM";
export const CLOSE_ALERT_FORM = "CLOSE_ALERT_FORM";
export const EDIT_SELECTED_ALERT = "EDIT_SELECTED_ALERT";
export const ALERT_FORM_TOOGLE_LOADING = "ALERT_FORM_TOOGLE_LOADING";
/* <---- ALERT ----> */
